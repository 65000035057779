<p [ngClass]="{'name': !ms?.isMobile, 'mobile-name': ms?.isMobile}">Jake Fishbain</p>
<div class="links">
  <div class="email"><mdb-icon far icon="envelope" class='icon'></mdb-icon>
    <a href="mailto:jakefishbain@gmail.com">Email</a>
  </div>
  <div class="github"><mdb-icon fab icon="github" class='icon'></mdb-icon>
    <a href='https://github.com/jakefishbain' target='_blank'>GitHub</a>
  </div>
  <div class="phone"><mdb-icon fas icon="mobile-alt" class='icon' ></mdb-icon>
    <a href='tel:8478042730'>Phone</a>
  </div>
  <div class="linkedin"><mdb-icon fab icon="linkedin" class='icon'></mdb-icon>
    <a href='https://www.linkedin.com/in/jakefishbain/' target='_blank'>LinkedIn</a>
  </div>
  <div class="resume"><mdb-icon far icon="file-alt" class='icon'></mdb-icon>
    <a href='http://resume.jakefishbain.com' target='_blank'>Résumé</a>
  </div>
</div>
