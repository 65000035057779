  <ul id="menu">
	<li data-menuanchor="landing"><a href="#landing">Home</a></li>
	<li data-menuanchor="experience_refs"><a href="#experience_refs">Experience & References</a></li>
	<li data-menuanchor="projects"><a href="#projects">Projects</a></li>
	<li data-menuanchor="education"><a href="#education">Education</a></li>
</ul>

<div fullpage id="fullpage" [options]="config" (ref)="getRef($event)" #fullpageRef>
    <div class="section"><app-landing-page></app-landing-page></div>
	<div class="section">
		<div class="slide">
			<app-experience></app-experience>
		</div>
		<div class="slide">
			<app-references></app-references>
		</div>
		<!-- <button (click)="fullpage_api.moveSectionDown()">Move down</button> -->
	</div>
	<div class="section">
		<app-projects></app-projects>
	</div>
	<div class="section">
		<app-education></app-education>
		<!-- <button (click)="fullpage_api.moveTo('experience_refs', 2)">Move to second page</button> -->
	</div>
</div>